<template>

<app-wrapper class="feed">

	<i class="fa fa-spinner fa-spin feed-loading" v-if="loading && !items.length" />

	<app-wrapper :content="true" class="feed-empty" v-if="!items.length && !loading">

		<p>No matching articles found. Try another filter.</p>

	</app-wrapper>

	<app-wrapper :content="true" class="feed-content" v-if="items.length">

		<app-card v-for="(item, index) in items" :item="item" :key="index" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	props: ['items', 'loading']

}

</script>

<style scoped>

.feed {
	padding: 0px 0px 64px 0px;
	min-height: 200px;
}

.is-tablet .feed {
	padding-bottom: 40px;
}

.feed-loading {
	font-size: 48px;
	color: #BBD9CD;
	line-height: 200px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.feed-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 40px;
}

.feed-empty {
	font-size: 24px;
	line-height: 200px;
	text-align: center;
	color: #ccc;
}

.is-tablet .feed-content {
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.is-mobile .feed-content {
	grid-template-columns: 1fr;
	grid-gap: 20px;
}

</style>
